<template>
  <div class="tab-container">
    <hcc-input
      type="text"
      size="lg"
      icon=""
      placeholder="Search..."
      class="tab__search-input"
      name="Search"
      :disabled="enableDrag"
      v-model="searchData"
    >
      <template #icon><magnify-icon /></template>
    </hcc-input>
    <div class="text__buttons">
      <div class="text__button-group" v-if="enableDrag">
        <hcc-button color="muted" @click="undoChanges">
          {{ $t("configuration.delete") }}
        </hcc-button>
        <hcc-button @click="saveChanges">
          {{ $t("channels-settings.save") }}</hcc-button
        >
      </div>
      <hcc-button
        v-else
        color="primary"
        :disabled="searchData.length > 0"
        @click="enableDrag = true"
      >
        {{ $t("campaigns.data-bank.text.change-order") }}
      </hcc-button>
    </div>
    <div class="text-tab-container">
      <draggable
        :list="messagesLists"
        @start="drag = true"
        @end="drag = false"
        draggable=".item"
        handle=".handle"
        class="text-tab-container"
      >
        <hcc-card class="text item" v-for="msg in messagesLists" :key="msg.id">
          <div
            class="text__item"
            :class="{ 'text-break': hasLongWord(msg.text) }"
          >
            <p>
              {{ msg.text | truncate(120) }}
            </p>
          </div>
          <div class="text__icon-left" v-if="enableDrag">
            <hcc-button-icon
              v-tooltip="$t('campaigns.data-bank.edit')"
              class="handle"
            >
              <reorder-icon />
            </hcc-button-icon>
          </div>
          <div class="text__icon">
            <hcc-button-icon
              @click="editModal(msg.id)"
              v-tooltip="$t('campaigns.data-bank.edit')"
            >
              <pencil-icon />
            </hcc-button-icon>
            <hcc-button-icon
              @click="deleteText(msg.id)"
              v-tooltip="$t('campaigns.data-bank.delete')"
            >
              <delete-icon />
            </hcc-button-icon>
          </div>
        </hcc-card>
        <hcc-card
          class="add-text"
          :class="{ text__disabled: enableDrag }"
          :color="enableDrag ? 'gray' : 'white'"
          @click="addTextModal"
        >
          <hcc-button-icon>
            <plus-circle-icon />
            <p>{{ $t("campaigns.data-bank.text.add") }}</p>
          </hcc-button-icon>
        </hcc-card>
      </draggable>
      <hcc-modal
        name="edit-textTab"
        :title="$t('campaigns.data-bank.text.modal-title')"
        @confirm="updateText"
        @cancel="clear"
        @opened="focusRefs"
      >
        <div class="modal">
          <hcc-textarea
            ref="inputText"
            :label="$t('campaigns.data-bank.text.modal-label')"
            :placeholder="$t('campaigns.data-bank.text.placehold')"
            :rows="10"
            name="text-tab"
            v-model="modalContent"
          />
          <hcc-button-icon class="add-text__params" @click="addParameter()">
            <plus-circle-icon />
          </hcc-button-icon>
        </div>
      </hcc-modal>
      <hcc-confirmation />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import EventBus from '@/eventBus';
import { UPDATE_DATA_BANK, CREATE_DATA_BANK } from '@/eventTypes';
import HccTextarea from '@/components/shared/HccTextarea/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  props: {
    databank: {
      required: true,
    },
  },
  components: {
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    draggable,
    HccTextarea,
    HccButton,
  },
  data() {
    return {
      messageList: [],
      modalContent: '',
      msg: {},
      searchData: '',
      enableDrag: false,
      originalList: [],
    };
  },
  watch: {
    'databank.text': function setText(newText) {
      this.messageList = [...newText];
      this.messageList.sort(this.orderList);
      this.originalList = [...this.messageList];
      this.clear();
    },
  },
  computed: {
    messagesLists() {
      return this.searchData.length > 0 ? this.messageList.filter((msg) => {
        const text = msg.text.toLowerCase();
        const finder = this.searchData.toLowerCase();
        return text.includes(finder);
      }) : this.messageList;
    },
    parametersCount() {
      return ((this.modalContent || '').match(/\{\{\d+\}\}/gi) || []).length;
    },

  },
  mounted() {
    this.clear();
    this.messageList = [...this.databank.text];
    this.messageList.sort(this.orderList);
    this.originalList = [...this.messageList];
  },
  activated() {
    this.enableDrag = false;
  },
  methods: {
    editModal(id) {
      this.msg = this.getMsg(id);
      this.modalContent = this.msg.text;
      this.$modal.show('edit-textTab');
    },
    deleteText(props) {
      this.$modal.show('confirmation', {
        title: this.$t('common.delete'),
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: () => {
          this.update({ id: props }, true);
        },
        cancel: () => this.clear(),
      });
    },
    addTextModal() {
      this.msg = null;
      this.$modal.show('edit-textTab');
    },
    getMsg(id) {
      return this.messageList.find(msg => msg.id === id);
    },
    updateText() {
      if (this.msg) {
        const data = {
          id: this.msg.id,
          text: this.modalContent,
        };
        this.update(data, false);
      } else {
        this.create();
      }
    },
    clear() {
      this.modalContent = '';
      this.msg = {};
    },
    create() {
      const data = {
        text: this.modalContent,
        order: this.messageList.length,
        type: 'text',
      };
      EventBus.$emit(CREATE_DATA_BANK, data);
      this.clear();
    },
    update(data, remove) {
      EventBus.$emit(UPDATE_DATA_BANK, { data, remove, type: 'text' });
      this.clear();
    },
    focusRefs() {
      this.$refs.inputText.focus();
    },
    addParameter() {
      const num = this.parametersCount + 1;
      this.modalContent = `${this.modalContent} {{${num}}}`;
      this.focusRefs();
    },
    undoChanges() {
      this.messageList = this.originalList;
      this.enableDrag = false;
    },
    saveChanges() {
      this.enableDrag = false;

      const changedTexts = [];

      this.originalList.forEach((text, i) => {
        if (text.id !== this.messageList[i].id) {
          changedTexts.push({ id: this.messagesLists[i].id, order: i });
        }
      });

      changedTexts.forEach(data => this.update(data, false));

      this.originalList = [...this.messageList];
    },
    orderList(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },
    hasLongWord(text) {
      const words = text.split(/\s+/);
      return words.some(word => word.length > 100);
    },
  },

};

</script>

<style scoped lang="scss">
@import "~styles/components/settings/dataBank/_text_tab.scss";
</style>
